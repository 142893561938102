import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Register from './pages/Register';
import ComingSoon from './pages/ComingSoon';

const Routing = () => {
    return (
        <Routes>
            {/* <Route element={<Register />} path="/register" exact /> */}
            <Route element={<ComingSoon />} path="/" exact />
        </Routes>
    );
}

export default Routing;