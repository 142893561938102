import React, { useState, useEffect } from 'react';

export default function ComingSoon() {
    const texts = [
        'Formando times e criando apostas.',
        'Aquecendo para grandes disputas.', 
        'Preparando a melhor plataforma de bolões.',
        'Em campo para trazer o melhor do bolão.',
        'Aguardem: o campeonato vai começar'
    ];
    const [text, setText] = useState('');
    const [index, setIndex] = useState(0);
    const [textIndex, setTextIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const typingSpeed = 50;
    const deletingSpeed = 30;
    const delay = 2000; // Delay before starting to delete or type

    useEffect(() => {
        if (!isDeleting && index < texts[textIndex].length) {
            // Typing
            const timer = setTimeout(() => {
                setText((prev) => prev + texts[textIndex].charAt(index));
                setIndex((prev) => prev + 1);
            }, typingSpeed);

            return () => clearTimeout(timer);
        } else if (!isDeleting && index === texts[textIndex].length) {
            // Wait before deleting
            const timer = setTimeout(() => setIsDeleting(true), delay);
            return () => clearTimeout(timer);
        } else if (isDeleting && index > 0) {
            // Deleting
            const timer = setTimeout(() => {
                setText((prev) => prev.slice(0, -1));
                setIndex((prev) => prev - 1);
            }, deletingSpeed);

            return () => clearTimeout(timer);
        } else if (isDeleting && index === 0) {
            // Move to next text
            const timer = setTimeout(() => {
                setIsDeleting(false);
                setTextIndex((prev) => (prev + 1) % texts.length);
            }, delay);

            return () => clearTimeout(timer);
        }
    }, [index, isDeleting, textIndex, texts]);

    return (
        <div class="bg-gray-200 font-sans leading-normal tracking-normal">
            <section style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://lncimg.lance.com.br/uploads/2023/07/dia-nacional-do-futebol_capa.jpg')` }} class="py-20">
                <div class="container mx-auto px-4">
                    <div className="text-white max-w-2xl mx-auto text-center">
                        <h1 class="text-4xl font-bold mb-6" id="demo">{text}</h1>
                        <p class="mb-6 text-white">Informe seu e-mail e receba as novidades e atualização<br></br> sobre a plataforma criar<strong className='text-primary-color'>bolao</strong>.com.br</p>
                        <form class="max-w-md mx-auto">
                            <div class="flex items-center">
                                <input type="email" class="bg-gray-100 mr-3 py-2 px-4 w-full rounded-md focus:outline-none focus:bg-white text-gray-600" placeholder="zezinhodobolao@gmail.com" />
                                <button type="submit" class="bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50">Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            <section class="bg-gray-200 py-20">
                <div class="container mx-auto px-4">
                    <div class="max-w-2xl mx-auto text-center">
                        <h2 class="text-3xl font-bold mb-6">Roadmap da Plataforma</h2>
                        <p class="text-gray-600 mb-12">
                            O criar<strong>bolao</strong>.com.br é uma plataforma exclusiva desenvolvida para facilitar o gerenciamento de bolões de um modo geral (futebol, basquete, vôlei, e-sports, programas de televisão, entre outros). Nossa ideia
                            não é ser apenas mais um site para gerenciamento de bolões, teremos funcionalidade exclusivas, fiquem ligados :)
                        </p>
                    </div>
                    <div class="flex flex-wrap -mx-4 mt-12">
                        <div class="w-full md:w-1/3 px-4 mb-8">
                            <div class="rounded-md bg-white shadow-md p-8">
                                <div class="text-4xl font-bold text-purple-600 mb-4">01</div>
                                <h3 class="text-2xl font-bold mb-4">Passo 1</h3>
                                <p class="text-gray-600 mb-4">Desenvolver a base do sistema, criar funcionalidades básicas e essênciais e subir para uso do público em geral.</p>
                            </div>
                        </div>
                        <div class="w-full md:w-1/3 px-4 mb-8">
                            <div class="rounded-md bg-white shadow-md p-8">
                                <div class="text-4xl font-bold text-purple-600 mb-4">02</div>
                                <h3 class="text-2xl font-bold mb-4">Passo 2</h3>
                                <p class="text-gray-600 mb-4">Criação das redes sociais, implementação de novos recursos exclusivos, investimento em marketing.</p>
                            </div>
                        </div>

                        <div class="w-full md:w-1/3 px-4 mb-8">
                            <div class="rounded-md bg-white shadow-md p-8">
                                <div class="text-4xl font-bold text-purple-600 mb-4">03</div>
                                <h3 class="text-2xl font-bold mb-4">Passo 3</h3>
                                <p class="text-gray-600 mb-4">Implementação do recebimento do bolão e rateio entre os vencedores automáticamente.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}